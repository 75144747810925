import React from "react"
import { Box } from "kui-basic"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { LoaderState } from "kui-crm"
import NotesBlock from "../../../../components/common/NoteBlock"
import Container from "../../../../components/ui/Container"
import useRCStore from "../../store"
import RCInfoFields from "../RCInfoFields"
import ClientPersonalDataCell from "../../../../components/common/ClientPersonalDataCell"
import ContractsTable from "../../../../components/common/ContractsTable"
import RCFieldsHeader from "../RCFieldsHeader"
import ChangesTable from "../../../../components/common/ChangesTable"
import { RCInfoFormFields } from "../RCInfoFields/types"
import { RCInfoFullSchema } from "../RCInfoFields/schemes"

function RCInfo() {
  const {
    notesStore,
    contractInfoStore,
    contractChangesStore,
    documentsStore,
  } = useRCStore()
  const serviceContracts = contractInfoStore.serviceContract
    ? [contractInfoStore.serviceContract]
    : []
  const form = useForm<RCInfoFormFields>({
    defaultValues: {
      files: documentsStore.documents,
      ...contractInfoStore.contractInfoValues,
    },
    ...(contractInfoStore.isPending && {
      resolver: yupResolver(RCInfoFullSchema),
    }),
  })

  return (
    <Box pt={2} pb={3}>
      <Container>
        <StyledStickyWrapper>
          <Box mb={2}>
            <StyledUserData
              type="left"
              label="Tenant"
              personalData={contractInfoStore.tenant}
            />
          </Box>

          <RCFieldsHeader form={form} />
        </StyledStickyWrapper>
        <RCInfoFields form={form} />
      </Container>

      <ContractsTable
        label="Service contract"
        serviceContracts={serviceContracts}
        withApartment
      />

      <Container>
        <ChangesTable
          variant="applied"
          changes={contractChangesStore.appliedChangesLines}
        />
        {notesStore && <NotesBlock notesStore={notesStore} />}
      </Container>

      <LoaderState loader={contractChangesStore.loader} />
    </Box>
  )
}

export default observer(RCInfo)

const StyledUserData = styled(ClientPersonalDataCell)`
  margin-top: 0;
  padding-right: 0;
`

const StyledStickyWrapper = styled.div`
  position: sticky;
  top: 121px;
  left: 0;
  background: white;
  z-index: 4;
`
